<template>
    <div>
        <b-row no-gutters>
            <b-col>
                <b-col class="box_header">
                    <h1>Редактировать профиль</h1>
                </b-col>
                <progress v-if="!uploadProgress" max="100" class="w-100" :value.prop="uploadPercentage"></progress>
                <div class="box_body">
                    <div v-if="preLoad" class="user_box">
                        <div class="user_box_image_box">
                            <div v-if="form.photo_250"><img v-if="form.photo_250" :src="form.photo_250.includes('http') ? form.photo_250 : 'https://city.ooogoroda.mobi/' + form.photo_250" alt="user_logo" class="img-fluid"></div>
                            <div v-else class="user_profile_no_photo">
                                <div class="user_icon_box user_incognito"></div>
                            </div>
                            <div class="user_box_image_buttons_box">
                                <button class="change_button">
                                    <b-form-file v-model="upload_file" ref="files" id="files" accept="image/jpeg, image/png, image/gif" class="" plain></b-form-file>
                                    <div class="icons_user change">
                                    </div>
                                </button>
                                <button class="delete_button" v-if="form.photo_250" @click="form.photo_250 = null">
                                    <div class="icons_user delete"></div>
                                </button>
                            </div>
                        </div>
                        <div class="box_input_box">
                            <input type="text" v-model="form.name" required>
                            <span class="bar"></span>
                            <label>Имя</label>
                        </div>
                        <div class="box_input_box">
                            <input type="text" v-model="form.f_name" required>
                            <span class="bar"></span>
                            <label>Фамилия</label>
                        </div>
                        <div class="form_add_button_box">
                            <button class="form_add_button" @click="send">Сохранить</button>
                        </div>
                    </div>
                    <div v-else>
                        <PreLoadBox></PreLoadBox>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    /* eslint-disable no-console */
export default {
    name: "UserGet",
    components: {

    },
    metaInfo() {
        return {
            title: `Редактировать профиль - `,
            meta: [
                {property: 'og:url', content: this.$config.url + this.$route.fullPath},
                {property: 'og:title', content: `Редактировать профиль - ${this.$myCity.name}`},
                {property: 'og:image', content:'https://okaygorod.com/images/logoHD.png'},
                {property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            preLoad: 'finish',
            upload_file: null,
            uploadPercentage: 0,
            uploadProgress: 'finish',
            form: {
                name: null,
                f_name: null,
                photo_250: null
            }
        }
    },

    methods: {
        api() {
            this.preLoad = null;
            let url = this.$config.api_url + this.$route.params.city + '/User.get';
            this.$http.get(url, {
                params: {
                    AccessToken: this.$user.token
                }
            }).then((response) => {
                this.$user.name = response.data.response.name;
                this.$user.f_name = response.data.response.f_name;
                this.$user.photo_250 = response.data.response.photo_250;
                this.form.name = response.data.response.name.trim();
                this.form.f_name = response.data.response.f_name.trim();
                this.form.photo_250 = response.data.response.photo_250;
            }).finally(() => {
                this.preLoad = 'finish';
            })
        },
        image_api() {
            let url = this.$config.api_url + this.$route.params.city + '/Storage.setImages';
            let formData = new FormData();
            let file = this.upload_file;
            formData.set('files[0]', file);
            formData.set('folder', 'user_avatar');
            this.uploadProgress = null;
            this.$http.post(url, formData, {
                onUploadProgress: e => {
                    this.uploadPercentage = Math.round(e.loaded * 100 / e.total);
                }
            }).then((response) => {
                this.form.photo_250 = response.data.response[0].path;
            }).finally(() => {
                this.upload_file = null;
                this.uploadProgress = 'finish';
                this.uploadPercentage = 0;
            })
        },
        send() {
            this.preLoad = null;
            let url = this.$config.api_url + this.$route.params.city + '/User.update';
            this.$http.post(url, this.form).then((response) => {
                this.$notify({
                    group: 'error',
                    type: 'success',
                    text: 'Данные успешно обновлены',
                    title: 'Поздравляем!',
                    duration: 10000,
                });
                console.log(response);
            }).catch((error) => {
                this.$notify({
                    group: 'error',
                    type: 'error',
                    text: error,
                    title: 'Ошибка!',
                    duration: 5000,
                });
            }).finally(() => {
                this.api();
                this.preLoad = 'finish';
            })
        }

    },
    mounted() {
        if (this.$user) {
            this.api();
        } else {
            this.$router.push({ name: 'Home' });
        }

    },
    watch: {
        '$user': function() {
            if (!this.$user) {
                this.$router.push({ name: 'Home' });
            }
        },
        'upload_file': function() {
            if (this.upload_file) {
                this.image_api();
            }

        }
    }
}
</script>
<style scoped>
@media only screen and (max-width: 767px) {}

@media only screen and (min-width: 768px) {}

button {
    border: none;
    outline: none;
    position: relative;
    cursor: pointer;
}

button:focus,
button:active {
    border: none;
    outline: none;
    cursor: pointer;
}

button:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

button:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.box_body {
    display: block;
}

.user_box {
    padding: 0 15px;
    max-width: 500px;
    margin: 0 auto;
}

.user_box_image_box {
    width: 100%;
    margin-bottom: 50px;
    border-radius: 50%;
    position: relative;

}

.user_box_image_box img {
    transition: 0.2s ease all;
    border-radius: 50%;
    border: 5px solid #4a76a8;
    height: 150px;
}

.user_box_image_box:hover img {
    filter: blur(3px);
}



.user_box_image_buttons_box {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: 0.2s ease all;
}

.user_box_image_box:hover .user_box_image_buttons_box {
    opacity: 1;
}



.icons_user {
    width: 20px;
    height: 20px;
}

.change_button {
    padding: 10px;
    background: #fff;
}


.change_button input {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    user-select: none;
    z-index: 1;
}

.delete_button {
    padding: 10px;
    background: #d50000;
    z-index: 2;
}

.change {
    background-color: #000 !important;
    mask-image: url('/images/icon/exchange.svg');
}

.delete {
    mask-image: url('/images/icon/delete.svg');
    background-color: #fff;
}

.user_profile_no_photo {
    background: #eee;
    margin: 0 auto;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    position: relative;
    border: 5px solid #4a76a8;
}

.user_icon_box {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.user_incognito {
    mask-image: url('/images/icon/user_incognito.svg');
    background-color: #4a76a8;
}
</style>